import { Component, OnInit, OnDestroy } from '@angular/core';
import { WpService } from '../../wp.service';
import { LanguageService } from '../../language.service';

@Component({
  selector: 'app-index',
  templateUrl: 'index.component.html',
  styleUrls: ['../about/about.component.scss']
})
export class IndexComponent implements OnInit, OnDestroy {

  isCollapsed = true;
  showMore = false;

  constructor(public wp: WpService, public ls: LanguageService) { }

  ngOnInit() {
    const body = document.getElementsByTagName('body')[0];
    body.classList.add('landing-page');
  }
  ngOnDestroy() {
    const body = document.getElementsByTagName('body')[0];
    body.classList.remove('landing-page');
  }

}
