import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { BrowserModule } from "@angular/platform-browser";
import { Routes, RouterModule } from "@angular/router";

import { IndexComponent } from "./pages/index/index.component";

import { AboutComponent } from "./pages/about/about.component";
import { NewsComponent } from "./pages/news/news.component";
import { OutputComponent } from "./pages/output/output.component";
import { PrivateComponent } from "./pages/private/private.component";
import { DetailComponent } from "./pages/detail/detail.component";


const routes: Routes = [
  { path: "", redirectTo: "home", pathMatch: "full" },
  { path: "home", component: IndexComponent },
  { path: "about", component: AboutComponent },
  { path: "news", component: NewsComponent },
  { path: "events", component: NewsComponent },
  { path: "output", component: OutputComponent },
  { path: "private", component: PrivateComponent },
  { path: "detail/:slug", component: DetailComponent }
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes, {
      useHash: true,
      scrollPositionRestoration: 'enabled'
    })
  ],
  exports: []
})
export class AppRoutingModule {}
