import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { WpService } from '../../wp.service';
import { LanguageService } from '../../language.service';

@Component({
  selector: 'app-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss']
})
export class DetailComponent implements OnInit {
  entry : any;
  content : any;

  constructor(private route: ActivatedRoute, public wp: WpService, private sanitizer: DomSanitizer, public ls: LanguageService) { }

  ngOnInit(): void {
    const _slug = this.route.snapshot.paramMap.get('slug');
    this.wp.getSinglePost(_slug)
      .subscribe(entries => {
        this.entry = entries[0];
        this.content = this.sanitizer.bypassSecurityTrustHtml(this.entry.content.rendered);
      });
  }

}
