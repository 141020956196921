import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { WpService } from '../../wp.service';
import { ActivatedRoute } from '@angular/router';
import { LanguageService } from '../../language.service';


@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss']
})

export class NewsComponent implements AfterViewInit {
  @ViewChild('eventsSection') eventsSectionRef: ElementRef;

  news: any;
  events: any;

  paginationEvents = 1;
  paginationNews = 1;

  constructor(public wp: WpService, private acivatedRoute: ActivatedRoute, public ls: LanguageService) { }


  ngAfterViewInit() {
    if (this.acivatedRoute.snapshot.routeConfig.path == 'events') {
      this.eventsSectionRef.nativeElement.scrollIntoView();
    }
  }

}
