import { Component, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { WpService } from '../../wp.service';

@Component({
  selector: 'app-output',
  templateUrl: './output.component.html',
  styleUrls: ['./output.component.scss']
})
export class OutputComponent implements OnInit, OnDestroy {
  entry: any;
  content: any;
  isCollapsed = true;
  constructor(public wp: WpService, private sanitizer: DomSanitizer) {
    this.wp.getSinglePost('projects-outputs')
      .subscribe(entries => {
        this.entry = entries[0];
        this.content = this.sanitizer.bypassSecurityTrustHtml(this.entry.content.rendered);
      });
  }

  ngOnInit() {
    const body = document.getElementsByTagName('body')[0];
    body.classList.add('profile-page');
  }
  ngOnDestroy() {
    const body = document.getElementsByTagName('body')[0];
    body.classList.remove('profile-page');
  }

}
