import { Component, OnInit } from '@angular/core';
import { WpService } from '../../wp.service';
import { DomSanitizer } from '@angular/platform-browser';

// Firebase App (the core Firebase SDK) is always required and must be listed first
import firebase from "firebase/app";
// If you are using v7 or any earlier version of the JS SDK, you should import firebase using namespace import
// import * as firebase from "firebase/app"

// If you enabled Analytics in your project, add the Firebase SDK for Analytics
import "firebase/analytics";

// Add the Firebase products that you want to use
import "firebase/auth";


@Component({
  selector: 'app-private',
  templateUrl: './private.component.html',
  styleUrls: ['./private.component.scss']
})
export class PrivateComponent implements OnInit {

  logIn = false;
  email = '';
  password = '';
  errorLogin = '';
  user = null;

  entrys: any;
  contents: any;

  constructor(public wp: WpService, private sanitizer: DomSanitizer) {

    if (firebase.apps.length == 0) {
      const firebaseConfig = {
        apiKey: "AIzaSyA2DHc13AAwCvW-1AJ0e0tI3RK6QycF-sw",
        authDomain: "gis4schools-eu.firebaseapp.com",
        databaseURL: "https://gis4schools-eu.firebaseio.com",
        projectId: "gis4schools-eu",
        storageBucket: "gis4schools-eu.appspot.com",
        messagingSenderId: "34959310001",
        appId: "1:34959310001:web:1d93c0f0b1845193beb690",
        measurementId: "G-14MLLWJK8B"
      };

      // Initialize Firebase
      firebase.initializeApp(firebaseConfig);
    }
   }

  ngOnInit(): void {
    this.entrys = this.wp.private;

    this.entrys.forEach(en => {
      en.contentSanitized = this.sanitizer.bypassSecurityTrustHtml(en.content.rendered);
    });

    if (firebase.auth().currentUser) {
      this.user = firebase.auth().currentUser;
    }

  }

  tryLogIn() {
    firebase.auth().signInWithEmailAndPassword(this.email, this.password)
      .then((userCredential) => {
        // Signed in
        this.user = userCredential.user;
        this.logIn = true;
        this.errorLogin = '';
      })
      .catch((error) => {
        var errorCode = error.code;
        this.errorLogin =  error.message;
        this.logIn = false;
      });
  }

  logOut() {
    firebase.auth().signOut();
    this.user = null;
  }

  recoverPwd(){
    firebase.auth().sendPasswordResetEmail(this.email).then(() => {
      // Email sent.
      this.errorLogin = 'Check your email inbox to reset your password.';
    }).catch((error) => {
      // An error happened.
      this.errorLogin = error.message;
    });
  }

}
