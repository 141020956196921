import { Component, OnInit, OnDestroy } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { LanguageService } from '../../language.service';


@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit, OnDestroy {

  isCollapsed = true;
  showMore = false;
  videoUrl : any;

  constructor(public ls: LanguageService, private sanitizer: DomSanitizer) {
    this.videoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.ls.languageStrings.video);
  }

  ngOnInit(): void {
    const body = document.getElementsByTagName('body')[0];
    body.classList.add('landing-page');
    this.videoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.ls.languageStrings.video);
  }
  ngOnDestroy() {
    const body = document.getElementsByTagName('body')[0];
    body.classList.remove('landing-page');
  }

  scroll(el: HTMLElement) {
    el.scrollIntoView();
  }
}
